import React, { Component, ErrorInfo, ReactNode } from 'react';

type Props = {
    children: ReactNode;
};
type State = {
    hasError: boolean;
    error: Error | null;
    errorInfo: ErrorInfo | null;
};

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Log error information
        console.error('Error:', error);
        console.error('Error Info:', errorInfo);

        if (process.env.REACT_APP_ENV === 'production') {
            /*setTimeout(() => {
                window.location.reload();
            }, 2000);
            */
        }
    }

    render() {
        if (this.state.hasError) {
            return <div style={{ height: 500, fontSize: 20, lineHeight: '500px', textAlign: 'center' }}>processing...</div>;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
